<template>
  <div class="text-center">
    <b-container fluid="xl">
      <div class="px-xl-5 px-md-3 px-0 mx-lg-5 py-5 savething">
        <!-- <h1 class="fw-bold">Vergelijk 17 trapliften van 6 fabrikanten</h1> -->
        <h1 class="fw-bold savething__title">
          Maak uw badkamer weer veilig met Mobilae
        </h1>
        <h2 class="savething__subTitle">
          Veilig en comfortabel gebruik maken van uw badkamer
        </h2>
        <div>
          <b-button
            @click="scrollToForm"
            class="btn-md px-md-5 px-3 py-3 fw-bold my-2 my-lg-5 savething__btn"
          >
            Vraag de gratis en vrijblijvende brochure aan
          </b-button>
        </div>
        <h5 class="savething__desc">
          We bieden het meest complete assortiment instapbaden, badliften,
          toiletdouches en instapdouches van verschillende merken. Je vindt het
          allemaal in onze badkamer brochure.
        </h5>
        <b-row cols-md="4" cols="2" class="mt-5">
          <b-col class="text-center d-flex justify-content-center">
            <img src="../../assets/one.png" class="w-50 savething__imgs" />
          </b-col>
          <b-col class="text-center d-flex justify-content-center">
            <img src="../../assets/two.png" class="w-50 savething__imgs" />
          </b-col>
          <b-col class="text-center d-flex justify-content-center">
            <img src="../../assets/three.png" class="w-50 savething__imgs" />
          </b-col>
          <b-col class="text-center d-flex justify-content-center">
            <img src="../../assets/four.png" class="w-50 savething__imgs" />
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "SaveThings_badkamers",
  methods: {
    scrollToForm() {
      const formSection = document.getElementById("form-section");
      if (formSection) {
        formSection.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss">
/* .fw-stronger {
  font-weight: 900 !important;
} */
.s-btn {
  /* padding: 1rem !important; */
  // width: fit-content;
  background-color: #ff5a00 !important;
  border: 0 !important;
  border-radius: 0px !important;
}

.savething {
  @media (max-width: 575px) {
    &__title {
      font-size: 1.3rem !important;
    }
    &__subTitle {
      font-size: 1.1rem !important;
    }
    &__desc {
      font-size: 1rem !important;
    }
    &__btn {
    }
    &__imgs {
      width: 100% !important;
    }
  }

  &__btn {
    background-color: #ff5a00 !important;
    border: 0 !important;
    border-radius: 15px !important;
  }
}
</style>

<template>
  <div>
    <b-container fluid="xl"> <Navbar /></b-container>
    <div class="hero"><img src="../assets/header_scootmobiel.png" /></div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "HeaderH",
  components: {
    Navbar,
  },
};
</script>

<style scoped></style>

<template>
  <b-navbar
    type="light"
    variant="transparent"
    class="d-flex justify-content-between px-xl-5 px-md-2 py-0"
  >
    <b-navbar-brand href="#" class="py-0"
      ><img src="../../assets/logoH.png" class="me-auto"
    /></b-navbar-brand>

    <b-navbar-brand class="py-0 mt-lg-3 mt-sm-2 mt-1 mb-auto">
      <a
        target="_blank"
        href="https://nl.trustpilot.com/review/mobilae.be?utm_medium=trustbox&utm_source=MicroStar"
        ><img src="../../assets/sterren.jpg" class="ms-auto" /></a
    ></b-navbar-brand>
  </b-navbar>
</template>

<script>
export default {
  name: "Navbar_badkamers",
};
</script>

<style scoped lang="scss">
.navbar-brand {
  img {
    width: 80%;
    @media (max-width: 990px) {
      width: 50%;
    }
  }
}
</style>

import Repository from "./Repository";
const LEADS = "/sponsors/2219/leads";
const LEADS_NL = "/sponsors/2305/leads";
import qs from "qs";

export default {
  postLead(payload) {
    return Repository.post(`${LEADS}`, qs.stringify(payload));
  },
  postLeadNl(payload) {
    console.log("payload: ", payload);
    return Repository.post(`${LEADS_NL}`, qs.stringify(payload));
  },
};

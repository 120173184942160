<template>
  <div class="bg-secondary px-xl-5 px-md-2 py-4">
    <b-container fluid="xl" class="px-sm-auto px-0">
      <div class="d-flex justify-content-center footerLinks">
        <b-link v-b-modal.modal-lg class="px-md-3 px-2 footerLinks__link"
          >Privacy Policy</b-link
        >
        <b-link v-b-modal.modal-lg1 class="px-md-3 px-2 footerLinks__link"
          >Impressum</b-link
        >
        <b-link v-b-modal.modal-lg2 class="px-md-3 px-2 footerLinks__link"
          >Cookie Statement</b-link
        >
        <b-link v-b-modal.modal-lg3 class="px-md-3 px-2 footerLinks__link"
          >Disclaimer</b-link
        >
        <b-link v-b-modal.modal-lg4 class="px-md-3 px-2 footerLinks__link"
          >Algemene Voorwaarden</b-link
        >
      </div>
      <b-modal id="modal-lg" size="lg" title="Privacy Policy" ok-only>
        <iframe
          sandbox="allow-scripts"
          src="https://leadgen.republish.nl/api/content/mobilae-privacy"
          width="100%"
          height="400px"
        >
        </iframe>
      </b-modal>
      <b-modal id="modal-lg1" size="lg" title="Impressum" ok-only>
        <iframe
          sandbox="allow-scripts"
          src="https://leadgen.republish.nl/api/content/mobilae-impressum"
          width="100%"
          height="400px"
        >
        </iframe>
      </b-modal>
      <b-modal id="modal-lg2" size="lg" title="Cookies" ok-only>
        <iframe
          sandbox="allow-scripts"
          src="https://leadgen.republish.nl/api/content/mobilae-cookie"
          width="100%"
          height="400px"
        >
        </iframe>
      </b-modal>
      <b-modal id="modal-lg3" size="lg" title="Disclaimer" ok-only>
        <iframe
          sandbox="allow-scripts"
          src="https://leadgen.republish.nl/api/content/mobilae-disclaimer"
          width="100%"
          height="400px"
        >
        </iframe>
      </b-modal>
      <b-modal id="modal-lg4" size="lg" title="Algemene Voorwaarden" ok-only>
        <iframe
          sandbox="allow-scripts"
          src="https://leadgen.republish.nl/api/content/mobilae-algemenevoorwaarden"
          width="100%"
          height="400px"
        >
        </iframe>
      </b-modal>
      <!-- <b-modal id="modal-lg3" size="lg" title="Algemene Voorwaarden" ok-only>
        <div class="pdfView">
          <vue-pdf-embed :source="source" />
        </div>
      </b-modal> -->
    </b-container>
  </div>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
export default {
  name: "Footer_badkamersNl",
  components: {
    VuePdfEmbed,
  },
  data() {
    return {
      source:
        "https://sponsor-tier-2-server.herokuapp.com/https://firebasestorage.googleapis.com/v0/b/host-only.appspot.com/o/general-conditions-be.pdf?alt=media&token=c2ab290a-07ff-4c04-9f4d-cec318696bb1",
    };
  },
};
</script>

<style lang="scss" scoped>
.bg-secondary {
  background-color: #133c46 !important;
}
a {
  color: #fff;
  text-decoration: none;
  @media (max-width: 600px) {
    font-size: 0.55rem;
  }
  &:hover {
    text-decoration: none !important;
    color: #fff;
  }
}
.pdfView {
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.footerLinks {
  @media (max-width: 575px) {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__link {
      font-size: 1rem !important;
    }
  }
}
</style>

<template>
  <div class="">
    <b-container fluid="xl">
      <div class="px-xl-5 px-md-2 mx-lg-2">
        <b-row cols-md="2" cols="1">
          <b-col class="text-start pe-lg-5 pe-1"
            ><div>
              <img
                src="@/assets/brochure.png"
                class="mt-md-n5 book-img mx-auto text-center d-flex"
              />
              <h1 class="display-4 fw-stronger">
                Blijf onafhankelijk en mobiel
              </h1>
              <h5 class="l-height">
                Naarmate we ouder worden, hebben we soms een extra hulpmiddel
                nodig om ons te helpen bewegen en mobiel te blijven. Een
                scootmobiel helpt u om overal zelfstandig heen te gaan en de
                dagelijkse dingen gewoon te doen. Bijvoorbeeld op bezoek bij een
                vriendin voor een kopje thee, naar de markt om vers fruit te
                kopen, of genieten van een lekker weertje buiten. Deze brochure
                is een verzameling van ons assortiment, van verschillende maten
                en verschillende eigenschappen.
              </h5>
              <h5 class="l-height">
                Mobilae is voor u de juiste keuzeL mocht er onverhoopt iets met
                uw scootmobiel gebeuren, dan geeft Mobilae service bij u aan
                huis.
              </h5>
              <h5 class="l-height fw-stronger">In deze brochure vindt u:</h5>
              <ol>
                <li class="h5 l-height mb-0">
                  Selectie van betaalbare scootmobielen van topmerken
                </li>
                <li class="h5 l-height mb-0">
                  Onze drie belangrijkste scootmobieltypes
                </li>
                <li class="h5 l-height mb-0">
                  Verschillende service en financiële hulp
                </li>
              </ol>
            </div></b-col
          >
          <b-col>
            <b-card
              ><div>
                <div v-if="show">
                  <h4 class="text-white">
                    <b>
                      Vrijblijvend meer informatie over de voordelen van
                      scootmobielen?</b
                    >
                  </h4>
                  <h4 class="text-white">
                    Vraag nu de <b>gratis en vrijblijvende</b> scootmobielen
                    brochure aan!
                  </h4>
                  <b-form @submit.prevent="onSubmit" class="text-start">
                    <b-form-group
                      class="text-white"
                      id="input-group-1"
                      label="Aanhef:"
                      label-for="input-1"
                    >
                      <b-form-select
                        id="input-1"
                        v-model="form.gender"
                        :options="gender"
                        required
                        class="form-control"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      class="text-white"
                      id="input-group-2"
                      label="Voornaam:"
                      label-for="input-2"
                    >
                      <b-form-input
                        id="input-2"
                        :state="fNameValidation"
                        v-model="form.firstname"
                        placeholder="Bv. Jan"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="text-white"
                      id="input-group-3"
                      label="Achternaam:"
                      label-for="input-3"
                    >
                      <b-form-input
                        id="input-3"
                        :state="lNameValidation"
                        v-model="form.lastname"
                        placeholder="Bv. Janssen"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="text-white"
                      id="input-group-4"
                      label="Telefoonnummer:"
                      label-for="input-4"
                    >
                      <b-form-input
                        id="input-4"
                        :state="phoneValidation"
                        v-model="form.phone_number"
                        type="tel"
                        placeholder="Bv. 0612345678"
                        maxlength="10"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="text-white"
                      id="input-group-5"
                      label="E-mail:"
                      label-for="input-5"
                    >
                      <b-form-input
                        :state="emailValidation"
                        id="input-5"
                        v-model="form.email"
                        type="email"
                        placeholder="Bv. jan.janssen@gmail.com"
                        required
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      id="input-group-6"
                      v-slot="{ ariaDescribedby }"
                      class="py-3 text-white"
                    >
                      <b-form-checkbox-group
                        v-model="form.answers"
                        id="checkboxes-6"
                        :aria-describedby="ariaDescribedby"
                        required
                      >
                        <b-form-checkbox
                          value="3839"
                          required
                          class="d-flex align-items-baseline"
                          ><h6 class="ms-3">
                            Ja, ik wil graag deze informatie ontvangen en
                            Mobilae mag mij per e-mail en/of telefoon
                            contacteren. Ik ga akkoord met de voorwaarden. *
                          </h6></b-form-checkbox
                        >
                      </b-form-checkbox-group>
                    </b-form-group>

                    <b-button
                      type="submit"
                      variant="primary"
                      class="s-btn btn-lg fw-bold"
                      ><b-spinner
                        small
                        v-if="getLoader"
                        class="text-center"
                      /><span v-else>BROCHURE AANVRAGEN >></span>
                    </b-button>
                  </b-form>
                </div>
                <h4 v-else class="text-white">
                  <b>
                    Bedankt voor uw aanvraag. Download nu uw opgevraagde
                    scootmobiel brochure!
                  </b>
                  <br />
                  <a
                    href="https://s3-eu-west-1.amazonaws.com/cdn.mobilae.nl/emag/nl_scootmobiel_brochure/index.html"
                    target="_blank"
                    class="text-decoration-none text-light"
                  >
                    <b-button
                      class="s-btn btn-lg fw-bold mt-3"
                      variant="primary"
                    >
                      Download nu
                    </b-button>
                  </a>
                  <img
                    v-if="profId != null"
                    referrerpolicy="no-referrer-when-downgrade"
                    :src="`https://rp0.nl/m/5693/3569027ec866/?event=6647&unique_conversion_id=${profId}`"
                    style="width: 1px; height: 1px; border: 0px"
                  />
                </h4>
              </div> </b-card
          ></b-col>
        </b-row>
      </div>
    </b-container>
    <div class="hero bg-color py-4 py-md-5 mt-4">
      <b-container fluid="xl">
        <div class="px-xl-5 px-md-2 mx-lg-2">
          <b-row cols-md="2" cols="1" class="align-items-center">
            <b-col class="text-start"
              ><div>
                <h1 class="fw-stronger">Waarom Mobilae?</h1>
                <h5 class="l-height">
                  Wij maken het u graag makkelijker! Bij Mobilae bieden wij u
                  een overzichtelijke selectie van de beste scootmobielen voor
                  ieder type gebruik (binnen, buiten, opvouwbaar, etc.) en in
                  elke prijsklasse. Zo vindt u altijd een scootmobiel die past
                  bij uw wensen! Maakt u binnenkort een proefrit bij u thuis of
                  bij onze showroom?
                </h5>
                <h5>
                  Neem een kijkje in onze scootmobielen brochure en zie welke
                  het beste bij u past!
                </h5>
                <br />
                <h5>
                  Mocht er onverhoopt iets met uw scootmobiel gebeuren, dan
                  geeft Mobilae service bij u aan huis.
                </h5>
              </div>
            </b-col>
            <b-col>
              <div>
                <img src="../assets/afspraak.png" class="w-100 rounded" />
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "FormSection",

  data() {
    return {
      validationCheck: false,
      emailErr: true,
      form: {
        language: "nl_NL",
        publisher_id: "",
        site_subid: "",
        site_custom_url: "https://mobilae.verbeterthuis.nl/scootmobielen",
        site_custom_name: "mobilae_scootmobielen",
        answers: [],
        gender: "null",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
      },
      gender: [
        { text: "Aanhef", value: null, disabled: true },
        { text: "Dhr.", value: "male", disabled: false },
        { text: "Mevrouw.", value: "female", disabled: false },
      ],
      show: true,
    };
  },

  methods: {
    ...mapActions(["postLead"]),
    async onSubmit() {
      if (this.validationCheck) {
        let resp = await this.postLead({
          ...this.form,
          site_subid: this.$route.query.oa_id,
          publisher_id: this.$route.query.site,
        });
        if (resp.success == true) {
          this.show = false;
          this.profId = resp.responseid;
        }
        console.log(resp);
      }
    },
  },
  computed: {
    ...mapGetters(["getLoader"]),
    fNameValidation() {
      if (this.form.firstname == "") {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;
      if (this.form.firstname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    lNameValidation() {
      if (this.form.lastname == "") {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;
      if (this.form.lastname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    emailValidation() {
      if (this.form.email == "") {
        this.validationCheck = false;
        return null;
      }
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)
      ) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    phoneValidation() {
      if (this.form.phone_number == "") {
        this.validationCheck = false;
        return null;
      }
      let phoneRegex = /^([0])(\d{9})$/;
      if (this.form.phone_number == "0") {
        return true;
      } else if (this.form.phone_number.match(phoneRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
$enable-negative-margins: true;

.custom-select {
  appearance: revert !important;
}
.form-control {
  padding: 0.75rem 0.75rem !important;
}
.s-btn {
  padding: 1rem !important;
  width: 100%;
  background-color: #ff5a00 !important;
  border: 0 !important;
  border-radius: 15px !important;
  @media (min-width: 767px) and (max-width: 990px) {
    padding: 1rem !important;
  }
}
.book-img {
  width: 100%;
  @media (max-width: 767.9px) {
    width: 50% !important;
  }
}
.card {
  margin: 50px 0;
  border-radius: 10px !important;
}
.card-body {
  background-color: #50a89e !important;
  color: white;
  padding: 40px !important;
  border-radius: 7px;
  @media (max-width: 990px) {
    padding: 30px 15px !important;
  }
}
.opacity {
  opacity: 0.6;
}
label {
  padding: 7px 0;
}
@media (min-width: 768px) {
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
}
.bg-color {
  background-color: #b9e6ff !important;
}
.l-height {
  line-height: 1.7 !important;
}
.btn-light-blue {
  background-color: #50a89e !important;
}
</style>

import { RepositoryFactory } from "../../repository/RepositoryFactory";

let leads = RepositoryFactory.get("leads");
const state = {
  getLoader: false,
  id: "",
  getErrorResponse: {}
};

const getters = {
  getLoader: (state) => state.getLoader,
  profId: (state) => state.id,
  getErrorResponses: (state) => state.getErrorResponse,
};

const actions = {
  async postLead({ commit }, payload) {
    commit("setGetLoader", true);
    try {
      const response = await leads.postLead(payload);
      commit("setGetLoader", false);
      const responseid = response.data.profile.id;
      commit("id", responseid);
      return {
        success: true,
        profileid: responseid,
      };
    } catch (error) {
      console.log(error.response);
      commit("setGetLoader", false);
      console.log(error.response.data.error);
      commit("id", null);
      return {
        success: true,
        profileid: null,
      };
    }
  },
  async postLeadNL({ commit }, payload) {
    commit("setGetLoader", true);
    try {
      if (payload.bootCheck == true) {
        commit("setGetLoader", false);

        return {
          success: true,
        };
      }
      else {
        const response = await leads.postLeadNl(payload);
        commit("setGetLoader", false);
        const responseid = response.data.profile.id;
        commit("id", responseid);
        return {
          success: true,
          profileid: responseid,
        };
      }

    } catch (error) {
      // console.log(error.response);
      commit("setGetLoader", false);
      // console.log(error.response.data.error);
      // commit("id", null);
      // return {
      //   success: true,
      //   profileid: null,
      // };
      if (error.response && error.response.data.error === "Conflict - duplicate") {
        console.log("error=>", error.response.data.error)
        commit("id", null);
        return {
          success: true,
        };
      } else {
        const errorKey = error.response.data.error.match(/'([^']+)'/)[0].slice(1, -1);
        commit("setError", { [errorKey]: false });
        console.error("Error Detail:", errorKey);
        return {
          success: false,
        };
      }
    }
  },
};

const mutations = {
  setGetLoader(state, payload) {
    state.getLoader = payload;
  },
  id(state, payload) {
    state.id = payload;
  },
  setError(state, payload) {
    state.getErrorResponse = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div class="home">
    <Header_badkamersNl />
    <FormSection_badkamersNl />
    <SaveThings_badkamersNl />
    <Footer_badkamersNl />
  </div>
</template>

<script>
// @ is an alias to /src
import Header_badkamersNl from "@/components/badkamersNl/Header_badkamersNl.vue";
import FormSection_badkamersNl from "@/components/badkamersNl/FormSection_badkamersNl.vue";
import SaveThings_badkamersNl from "@/components/badkamersNl/SaveThings_badkamersNl.vue";
import Footer_badkamersNl from "@/components/badkamersNl/Footer_badkamersNl.vue";

export default {
  name: "Home_badkamersNl",
  components: {
    Header_badkamersNl,
    FormSection_badkamersNl,
    SaveThings_badkamersNl,
    Footer_badkamersNl,
  },
  mounted() {
    let useScript = document.createElement("script");
    useScript.setAttribute(
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      ),
      fbq("init", "468081108442050"),
      fbq("track", "PageView")
    );
    document.head.appendChild(useScript);
  },
  created() {
    this.noscriptHtml =
      '<noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=468081108442050&ev=PageView&noscript=1" /></noscript>';
  },
};
</script>

<style lang="scss">
.home {
  overflow: hidden !important;

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 1200px) {
      max-width: 1140px;
    }
    @media (min-width: 992px) {
      max-width: 960px;
    }
    @media (min-width: 768px) {
      max-width: 720px;
    }
    @media (min-width: 576px) {
      max-width: 540px;
    }
  }
}
</style>
